import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"

const VentesLyneSectionWrapper = styled.div`
  width: 100%;
  position: relative;
`

const VentesContainer = styled.div`
  width: 100%;
  max-width: 1900px;
  margin: 0px auto;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const ButtonTitle = styled.span`
  display: none;
`

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  margin: 0px 10px 0px 0px;
  background: #e7f0f3;

  @media (max-width: 767px) {
    margin: 0px 5px 0px 0px;
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`

const ChristmasTextWrapper = styled.div`
  width: 100%;
  max-width: 750px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #262626;

  @media (max-width: 600px) {
    padding: 20px;
  }
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #262626;
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #262626;

  @media (max-width: 992px) {
    flex-direction: row;
    margin: 20px 0px 0px;
  }
`

const Title = styled.div`
  font-size: 35px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  text-align: center;

  span {
    // @media (max-width: 992px) {
    //   display: block;
    // }
  }

  @media (max-width: 1600px) {
    font-size: 30px;
  }

  @media (max-width: 992px) {
    font-size: 24px;
  }
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  text-align: center;

  strong {
    font-weight: bold;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
  }

  @media (max-width: 992px) {
    text-align: left;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
  margin: 0px 0px 0px 10px;

  @media (max-width: 767px) {
    margin: 0px 0px 0px 5px;
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 2;
`

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  top: 0px;
  object-fit: cover;
`

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  div {
    position: relative;
  }

  iframe,
  video {
    width: 100% !important;
    height: 100% !important;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px;

    @media (max-width: 1200px) {
      width: 110% !important;
      height: 110% !important;
    }

    @media (max-width: 992px) {
      width: 150% !important;
      height: 150% !important;
    }
  }
`

const TopSection = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  min-height: 400px;

  @media (max-width: 1200px) {
    min-height: 350px;
  }

  @media (max-width: 850px) {
    min-height: 300px;
  }

  @media (max-width: 480px) {
    min-height: 240px;
  }
`

const LogoImage = styled.img`
  width: 100%;
  max-width: 110px;
  margin: 40px 0px;

  @media (max-width: 992px) {
    max-width: 90px;
    margin: 0px 15px 0px 0px;
  }
`

const MedicalImage = styled.img`
  width: 100%;
  max-width: 140px;
  margin: 0px;
  position: absolute;
  top: 10%;
  right: 10%;

  @media (max-width: 1600px) {
    max-width: 120px;
    top: 3%;
    right: 3%;
  }

  @media (max-width: 1200px) {
    max-width: 80px;
    top: 15px;
    right: 15px;
  }

  @media (max-width: 1024px) {
    position: relative;
    margin-bottom: 20px;
    align-self: flex-end;
    top: auto;
    right: auto;
  }

  @media (max-width: 992px) {
    margin-bottom: 0px;
  }
`

const VentesLyneSection = ({ intl, data }) => {
  return (
    data && (
      <VentesLyneSectionWrapper>
        <VentesContainer>
          <LeftSection>
            {data.url && (
              <AbsoluteButton to={data.url}>
                <ButtonTitle>Découvrir</ButtonTitle>
              </AbsoluteButton>
            )}
            <ChristmasTextWrapper>
              <TitleWrapper>
                {data.top_image && (
                  <MedicalImage
                    className="lazyload"
                    data-src={data.top_image}
                    alt={data.top_image_alt ? data.top_image_alt : ""}
                  />
                )}
                {data.title && (
                  <Title
                    dangerouslySetInnerHTML={{
                      __html: data.title,
                    }}
                  />
                )}
              </TitleWrapper>
              <TextWrapper>
                {data.logo_image && (
                  <LogoImage
                    className="lazyload"
                    data-src={data.logo_image}
                    alt={data.logo_alt ? data.logo_alt : ""}
                  />
                )}
                {data.text && (
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: data.text,
                    }}
                  />
                )}
              </TextWrapper>
            </ChristmasTextWrapper>
          </LeftSection>
          <RightSection>
            {data.url && (
              <AbsoluteButton to={data.url}>
                <ButtonTitle>Découvrir</ButtonTitle>
              </AbsoluteButton>
            )}
            <TopSection>
              {data.image && (
                <BannerImage
                  className="lazyload"
                  data-src={data.image}
                  alt={data.alt ? data.alt : ""}
                />
              )}

              <VideoWrapper>
                <ReactPlayer
                  url={
                    "https://www.percko.com/videos/percko.com/eur-fr/solutions-textiles/solutions_textiles_-_video_1.mp4"
                  }
                  playing={true}
                  controls={false}
                  muted={true}
                  loop={true}
                  width="100%"
                  height="100%"
                  playsinline={true}
                  playsInline
                />
              </VideoWrapper>
            </TopSection>
          </RightSection>
        </VentesContainer>
      </VentesLyneSectionWrapper>
    )
  )
}

export default injectIntl(VentesLyneSection)
